
<script setup lang="ts">
    import { inject } from "vue";
    import useCheckItems from "./CheckItems.Composables.useCheckItems.ts";
    import { isMobile } from "o365.GlobalState.ts";

    const { checkItems } = inject<ReturnType<typeof useCheckItems>>("CheckItems");
</script>

<template>
    <div class="flex-shrink-0 order-2 order-md-3" :class="{ 'flex-grow-1 d-flex justify-content-end': !isMobile }">
        <div v-if="checkItems.statistics && (checkItems.statistics.Section_ID != null || checkItems.statistics.Step_ID != null)" class="">
            <span class="" style="font-weight:600" v-if="!checkItems.statistics.ItemsComplete && checkItems.statistics.TotalItems > 0">
                {{ checkItems.statistics.CompletedItems }} / {{ checkItems.statistics.TotalItems }} {{ $t("check items complete") }}
            </span>

            <span class="" style="font-weight:600;display:inline" v-if="checkItems.statistics.ItemsComplete">
                <span v-if="checkItems.statistics.SignaturesComplete && checkItems.statistics.TotalSignatures>0" style="color:green;"><i class="bi bi-check-circle"></i> {{ $t("Checklist Complete and Signed") }}</span>
                <span v-if="!checkItems.statistics.SignaturesComplete && checkItems.statistics.TotalSignatures>0" style="color:blue;"><i class="bi bi-check-circle "></i> {{ $t("Checklist Complete, awaiting Signatures") }}</span>
                <span v-if="checkItems.statistics.TotalSignatures==0" style="color:green;"><i class="bi bi-check-circle "></i> {{ $t("Checklist Complete") }}</span>
            </span>
        </div>
    </div>
</template>
